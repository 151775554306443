<template>
  <ion-content class="ion-padding">
    <div>
         <center><ion-title size="large">Reclasin </ion-title></center>   
      <br/>
      <div >
        
        <ion-input type="text" v-model="name" placeholder="Ingrese el nombre" />
        <ion-input type="email" v-model="email" placeholder="Ingrese el correo electrónico" />
        <ion-input type="text" v-model="user_name" placeholder="Ingrese el Nombre de Usuario" />
        <ion-input type="password" v-model="password" placeholder="Ingrese la contraseña" />
        <ion-input type="password" v-model="passwordConfirmation" placeholder="Ingrese la confimación de contraseña" />
      
        <ion-button color="dark" @click="register()">
          Enviar
        </ion-button>
        <ion-button color="dark" @click="redirect()">
              Login
        </ion-button>
        <br>
        
        <center> <p><b>o</b></p></center>
         
        <div style="justify-content: center;display: flex;">
            <img src="/assets/icon/icon-facebook.png" @click="loginFacebook" >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img src="/assets/icon/icon-google.png" @click="loginGoogle" >
          </div>
      </div>
    </div>
  </ion-content>
</template>

<script>
import {  IonButton, IonInput } from "@ionic/vue";
import axios from 'axios'
import toast from '@/plugins/toast'
//import Form from "../components/Form";
//import People from "../components/People";
//import PayCardOverview from "../components/PayCardOverview";

export default {
  name: "pay",
  title: "Pay",
  requiresAuth: false,
  components: {
    //IonPage,
    IonInput,
    //Form,
    //People,
    //PayCardOverview,
    IonButton
  },
  data() {
    return {
      dni : '',
      name : '',
      lastname : '',
      email : '',
      password : '',
      passwordConfirmation : '',
    };
  },
  methods: {
     redirect(){
      location.href = "/login"
    },
    async register(){
      var loading = await toast.showLoading()

      await loading.present();

      const data = {
          user_name : this.user_name,
          name : this.name,
          email : this.email,
          password : this.password,
          password_confirmation : this.passwordConfirmation,
      };

      axios.post('/api/auth/register',data)
      .then(res => {
        loading.dismiss()
        console.log(res)
        toast.openToast("Registro Exitoso","error",2000)
        location.href = "/login"

      })
      .catch(error =>{
        loading.dismiss()
        console.log(error)
      })
    }
  },
};
</script>

<style scoped>

ion-label {
  font-weight: bold;
}

ion-input {
  background: rgba(63,81,181,.15);
  border-radius: 20px;
  padding: 5px 8px !important;
  margin: 10px 0;
}

ion-button {
  width: auto;
  --border-radius: 20px;
  height: 35px;
  text-transform: none;
}
</style>
